<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a reportes
    </v-btn>
    <!-- begin::Page header and title -->
    <div class="card-header flex-nowrap border-0 pt-6 pb-0 rounded-lg pb-5">
      <div class="card-title">
        <div class="card-label">
          <h3>
            Reporte de planta de docentes
          </h3>
          <div class="text-muted text-body-1">
            Reporte con la información sobre la planta de docentes e
            instructores, con sus asignaturas y módulos.
          </div>
        </div>
      </div>
      <v-container class="pb-5">
        <v-row>
          <v-col cols="12" md="8" xl="4" class="pa-0">
            <v-card class="rounded score-sheet-card-large " outlined>
              <v-list-item three-line>
                <!-- begin::card title -->
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Docentes e instructores
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    Planta de docentes e instructores
                  </v-list-item-title>
                  <p class="text-body-2 ma-0">
                    Listado de docentes e instructores con las asignturas y
                    módulos que imparten.
                  </p>
                </v-list-item-content>
                <!-- end::card title -->
                <!-- begin::card icon -->
                <v-list-item-avatar class="rounded success" tile icon size="80">
                  <v-icon x-large color="white" class="ml-n1"
                    >mdi-microsoft-excel</v-icon
                  ></v-list-item-avatar
                >
                <!-- end::card icon -->
              </v-list-item>
              <!-- begin:: generate teacher faculty btn -->
              <v-card-actions>
                <v-btn
                  outlined
                  rounded
                  text
                  :href="generateTeacherFacultyReport"
                  download
                >
                  Generar reporte
                </v-btn>
              </v-card-actions>
              <!-- end:: generate teacher faculty btn -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- end::Page header and title -->
  </div>
</template>

<script>
export default {
  name: "TeacherFaculty",
  title: "Planta de docentes | GE ITR",
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    reportsBaseURL() {
      let baseURL;
      if (process.env.REPORTS_APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.REPORTS_APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },

    generateTeacherFacultyReport() {
      return `${this.reportsBaseURL}/subject-teachers/list`;
    },
  },
};
</script>
